import React, { useEffect, useRef, useState } from 'react';
import requests from "../../axios/requests";
import { motion, AnimatePresence } from "framer-motion";

import MobileSlider from "../../components/MobileSlider/MobileSlider";
import Dots from "../../components/Dots/Dots";

import request_icon from '../../assets/services/request_icon.png';
import './Services.scss';
import helpFunctions from "../../helpers/helpFunctions";

const Services = ({setOpenModal, activeSection, setCurrentColorHeader, scrollToAnchor}) => {
    const [data, setData] = useState([]);
    const [activeSlide, setActiveSlide] = useState(0);
    const [showListAnimation, setShowListAnimation] = useState(false);

    const refSlider = useRef();
    const refPageMobile = useRef();
    const listAnimation = {
        hidden: {opacity: 0},
        visible: (custom) => {
            if (showListAnimation)
                return {
                    opacity: 1,
                    transition: {delay: custom}
                }
        }
    }
    const h1_animation = {
        hidden: {opacity: 0},
        visible: () => {
            if (showListAnimation)
                return {
                    opacity: 1,
                    transition: {
                        duration: 2,
                        delay: 1.1
                    }
                }
        }
    };

    let eventScroll = () => {
        helpFunctions.isScrolledIntoHeader(
            refPageMobile.current,
            "services_color",
            setCurrentColorHeader
        )
    }

    useEffect(() => {
        requests.fetch_services_machinery("machinery", 1, 20)
            .then(res => setData(res.data.results))
            .catch(err => console.error(err))

        if (window.innerWidth < 991) {
            window.addEventListener("scroll", eventScroll);
            return () =>
                window.removeEventListener("scroll", eventScroll);
        }
    }, [])
    useEffect(() => {
        if (activeSection === 1)
            setShowListAnimation(true);
    }, [activeSection])
    useEffect(() => {
        if (scrollToAnchor)
            refPageMobile.current.scrollIntoView({block: "start", behavior: "smooth"})
    }, [scrollToAnchor])
    return (
        <>
            <div className="section services full_height-section">
                <div className="container">
                    <main>
                        <motion.div
                            className="h1"
                            variants={h1_animation}
                            initial="hidden"
                            animate="visible"
                        >
                            Услуги спецтехники
                        </motion.div>
                        <motion.ul className="list list_style">
                            <AnimatePresence>
                                {data.length > 0 &&
                                    data.map((elem, indx) =>
                                        <motion.li
                                            key={elem.id}
                                            variants={listAnimation}
                                            initial="hidden"
                                            animate="visible"
                                            exit="hidden"
                                            custom={(indx + 1) * 0.4}
                                            onClick={() => setOpenModal("services_modal", elem)}
                                        >
                                            <div
                                                className="machine_image"
                                                style={{
                                                    background: `url(${elem.photo}) no-repeat`,
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "30% 50%"
                                                }}
                                            />
                                            <div className="info">
                                                <div className="name">
                                                    {elem.name}
                                                </div>
                                                {elem.price &&
                                                    <div className="cost">
                                                        {elem?.price_type === "passage"
                                                            ? <span>{elem.price} р/рейс</span>
                                                            : <span>{elem.price} р/час</span>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </motion.li>
                                    )}

                                <li
                                    className="feedback_request"
                                    onClick={() => setOpenModal("services_modal")}
                                >
                                    <div className="row">
                                        <span>оставить</span>
                                        <img src={request_icon} alt="request_icon"/>
                                    </div>
                                    <div className="row">
                                        <div className="group_text">
                                            <span> не нашли </span>
                                            <span> что искали?</span>
                                        </div>
                                        <span> заявку </span>
                                    </div>
                                </li>
                            </AnimatePresence>
                        </motion.ul>
                    </main>
                </div>
            </div>
            <div className="services_mobile" ref={refPageMobile}>
                <div className="h1_title">
                    <span>Услуги</span>
                    <span>спецтехники</span>
                </div>
                <div className="slider_section">
                    <MobileSlider
                        data={data}
                        setOpenModal={setOpenModal}
                        refSlider={refSlider}
                        setActiveSlide={setActiveSlide}
                        isService={true}
                        key={"isService"}
                    />
                </div>
                <Dots
                    data={data}
                    refSlider={refSlider}
                    setActiveSlide={setActiveSlide}
                    activeSlide={activeSlide}
                />
                <div
                    className="feedback_request"
                    onClick={() => setOpenModal("services_modal")}
                >
                    <div className="row">
                        <span>оставить</span>
                        <img src={request_icon} alt="request_icon"/>
                    </div>
                    <div className="row">
                        <div className="group_text">
                            <span> не нашли </span>
                            <span> что искали?</span>
                        </div>
                        <span> заявку </span>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Services;