import React, { useEffect, useRef, useState } from 'react';
import requests from "../../axios/requests";
import { motion, AnimatePresence } from "framer-motion";
import helpFunctions from "../../helpers/helpFunctions";
import cn from 'classnames';

import MobileSlider from "../../components/MobileSlider/MobileSlider";
import Dots from "../../components/Dots/Dots";

import arrow from '../../assets//news/arrow.svg';
import arrow_s from '../../assets//news/arrow_s.svg';
import news_icon from '../../assets//news/news_icon.png';
import Slider from "react-slick";
import img from "../../assets/planetObjects/image_slider.png";
import './News.scss';

const News = ({setOpenModal, activeSection, setCurrentColorHeader, scrollToAnchor}) => {
    const [activeType, setActiveType] = useState(0);
    const [data, setData] = useState({});
    const [activeSlide, setActiveSlide] = useState(0);
    const [activeSlideChildren, setActiveSlideChildren] = useState(0);
    const [showListAnimation, setShowListAnimation] = useState(false);

    const refSlider = useRef();
    const refSliderMobile = useRef();
    const refSliderMobileList = useRef();
    const refPageMobile = useRef();
    const calcCountSlide = () => {
        const countNews = data?.results?.length;
        if (countNews < 3)
            return countNews;
        else
            return 3;
    }
    const settings = {
        dots: false,
        infinite: true,
        adaptiveHeight: true,
        centerMode: false,
        arrows: false,
        slidesToShow: calcCountSlide(),
        slidesToScroll: 1,
        className: "slider_images",
        speed: 1000
    };
    const h1_animation = {
        hidden: {opacity: 0},
        visible: (custom) => {
            if (showListAnimation)
                return {
                    opacity: 1,
                    transition: {
                        duration: 2,
                        delay: custom || 0.2
                    }
                }
        }
    };
    const listAnimation = {
        hidden: {opacity: 0},
        visible: (custom) => {
            if (showListAnimation)
                return {
                    opacity: 1,
                    transition: {
                        duration: 2,
                        delay: custom
                    }
                }
        }
    }

    let fetchData = (type) => {
        requests.fetch_news(type, 1, 20)
            .then(res => setData(res.data))
            .catch(err => console.error(err))
    }
    let eventScroll = () => {
        helpFunctions.isScrolledIntoHeader(
            refPageMobile.current,
            "news_color",
            setCurrentColorHeader
        )
    }

    useEffect(() => {

        if (window.innerWidth < 991) {
            window.addEventListener("scroll", eventScroll);
            return () =>
                window.removeEventListener("scroll", eventScroll);
        }
    }, [])
    useEffect(() => {
        if (activeType === 0)
            fetchData("company")
        if (activeType === 1)
            fetchData("general")
        if (data?.results?.length > 0)
            setData({})
    }, [activeType])
    useEffect(() => {
        if (activeSection === 6)
            setShowListAnimation(true);
    }, [activeSection])
    useEffect(() => {
        if (scrollToAnchor)
            refPageMobile.current.scrollIntoView({block: "start", behavior: "smooth"})
    }, [scrollToAnchor])
    return (
        <>
            <div className="section news full_height-section">
                <div className="container">
                    <main>
                        <div className="news_main">
                            <motion.div
                                className="h1"
                                variants={h1_animation}
                                initial="hidden"
                                animate="visible"
                            >
                                новости
                            </motion.div>
                            {Array(2).fill(0).map((_, idnx) =>
                                <motion.div
                                    className={cn("event_band", {
                                        "active": idnx === activeType,
                                        "build": idnx === 1
                                    })}
                                    onClick={() => setActiveType(idnx)}
                                    variants={h1_animation}
                                    initial="hidden"
                                    animate="visible"
                                    custom={(idnx + 1) * 0.3}
                                    key={idnx}
                                >
                                    {idnx === 0
                                        ? <>
                                            {activeType === 0 && data?.count
                                                ? <div className="count_news">
                                                    Всего новостей : {data?.count}
                                                </div>
                                                : <></>
                                            }
                                            <div className="label">
                                                лЕНта
                                                информационных
                                                СОБЫТИЙ
                                            </div>
                                        </>
                                        : <>
                                            {activeType === 1 && data?.count > 0
                                                ? <div className="count_news">
                                                    Всего новостей : {data?.count}
                                                </div>
                                                : <></>
                                            }
                                            <div className="label">
                                                <span> новости</span>
                                                <span> из мира</span>
                                                <span> стоительства</span>
                                            </div>
                                        </>
                                    }
                                    <div className="arrows">
                                        <div
                                            className="arrow"
                                            onClick={() => refSlider?.current?.slickPrev()}
                                        >
                                            <img src={arrow} alt="arrow"/>
                                        </div>
                                        <div
                                            className="arrow"
                                            onClick={() => refSlider?.current?.slickNext()}
                                        >
                                            <img src={arrow} alt="arrow"/>
                                        </div>
                                        <img
                                            className="news_icon"
                                            src={news_icon}
                                            alt="news_icon"
                                        />
                                    </div>
                                </motion.div>
                            )}
                        </div>
                        <div className="slider">
                            {data?.results?.length > 0 &&
                                <Slider
                                    ref={refSlider}
                                    beforeChange={(_, indx) => {
                                    }}
                                    {...settings}
                                >
                                    {data.results.map((elem, indx) =>
                                        <div key={indx}>
                                                <motion.div
                                                    className="news_elem"
                                                    onClick={() => setOpenModal("news_modal", elem)}
                                                    variants={listAnimation}
                                                    initial="hidden"
                                                    animate="visible"
                                                    exit="hidden"
                                                    custom={(indx + 1) * 0.4}
                                                >
                                                    <div className="date_row">
                                                        <div className="h1">
                                                            {helpFunctions.dateToObj(elem.date).month}
                                                        </div>
                                                        <div className="year">
                                                    <span>
                                                        {helpFunctions.dateToObj(elem.date).year}
                                                    </span>
                                                            <div className="arrow">
                                                                <img
                                                                    src={arrow_s}
                                                                    alt="arrow_s"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <img
                                                        className="preview"
                                                        src={elem.image}
                                                        alt="img"
                                                    />
                                                    <div className="label">
                                                        {elem.title}
                                                    </div>
                                                </motion.div>
                                        </div>
                                    )}
                                </Slider>
                            }
                        </div>
                    </main>
                </div>
            </div>
            <div className="news_mobile" ref={refPageMobile}>
                <div className="h1">Новости</div>
                <div className="slider_section">
                    <MobileSlider
                        data={[1, 2]}
                        refSlider={refSliderMobile}
                        setActiveSlide={(indx) => {
                            setActiveSlide(indx)
                            setActiveType(indx)
                        }}
                        isNews={true}
                        setOpenModal={setOpenModal}
                        key={"isNews"}
                        anotherRef={refSliderMobileList}
                    />
                    <Dots
                        data={[1, 2]}
                        refSlider={refSliderMobile}
                        setActiveSlide={setActiveSlide}
                        activeSlide={activeSlide}
                    />
                </div>
                <div className="slider_section bottom">
                    <MobileSlider
                        data={data?.results}
                        refSlider={refSliderMobileList}
                        setActiveSlide={(indx) => {
                            if (indx === -1)
                                setActiveSlideChildren(0)
                            else
                                setActiveSlideChildren(indx)
                        }}
                        isNewsChildren={true}
                        setOpenModal={setOpenModal}
                        key={"isNews_children"}
                        anotherRef={refSliderMobileList}
                    />
                    <Dots
                        data={data?.results}
                        refSlider={refSliderMobileList}
                        setActiveSlide={(indx) => {
                            console.log(indx, 'indx')
                            if (indx === -1)
                                setActiveSlideChildren(0)
                            else
                                setActiveSlideChildren(indx)

                        }}
                        activeSlide={activeSlideChildren}
                    />
                </div>
            </div>
        </>
    );
};

export default News;