import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import requests from "../../axios/requests";

import MobileSlider from "../../components/MobileSlider/MobileSlider";
import Dots from "../../components/Dots/Dots";

import arrow from '../../assets/builtObjects/arrow.svg';
import './BuiltObjects.scss';
import helpFunctions from "../../helpers/helpFunctions";

const BuildObjects = ({setOpenModal, activeSection, setCurrentColorHeader, scrollToAnchor}) => {
    const [data, setData] = useState([]);
    const [activeSlide, setActiveSlide] = useState(0);
    const [showListAnimation, setShowListAnimation] = useState(false);
    const [sliderHeight, setSliderHeight] = useState(0);

    const refSliderMobile = useRef();
    const refSliderContainer = useRef();

    const refPageMobile = useRef();
    const listAnimation = {
        hidden: {opacity: 0},
        visible: (custom) => {
            if (showListAnimation)
                return {
                    opacity: 1,
                    transition: {delay: custom}
                }
        }
    }
    const h1_animation = {
        hidden: {opacity: 0},
        visible: () => {
            if (showListAnimation)
                return {
                    opacity: 1,
                    transition: {
                        duration: 2,
                        delay: 0.4
                    }
                }
        }
    };

    let eventScroll = () => {
        helpFunctions.isScrolledIntoHeader(
            refPageMobile.current,
            "builds_color",
            setCurrentColorHeader
        )
    }
    let getCurrentHeight = () => {
        if(refSliderContainer?.current) {
            const {height} = refSliderContainer.current.getBoundingClientRect();
            if (height)
                setSliderHeight(height - 30)
        }
    }

    useEffect(() => {
        requests.fetch_object("built", 1, 50)
            .then(res => {
                const data = res.data.results;
                setData(data);
            })
            .catch(err => console.error(err))
        if (window.innerWidth < 991) {
            window.addEventListener("scroll", eventScroll);
            return () =>
                window.removeEventListener("scroll", eventScroll);
        }
    }, [])
    useEffect(() => {
        if (activeSection === 5)
            setShowListAnimation(true);
    }, [activeSection])
    useEffect(() => {
        if (scrollToAnchor)
            refPageMobile.current.scrollIntoView({block: "start", behavior: "smooth"})
    }, [scrollToAnchor])
    useEffect(() => {
        if (window.innerWidth <= 990)
            setTimeout(() => {
                getCurrentHeight();
            }, 1000)
    }, [data])
    return (
        <>
            <div className="section built_objects full_height-section">
                <div className="container">
                    <motion.div
                        className="h1"
                        variants={h1_animation}
                        initial="hidden"
                        animate="visible"
                    >
                        Построенные объекты
                    </motion.div>
                    <main>
                        <motion.ul className="list list_style">
                            <AnimatePresence>
                                {data?.length > 0 &&
                                    data.map((elem, indx) =>
                                        <motion.li
                                            onClick={() => setOpenModal("build_object_modal", elem)}
                                            key={elem.id}
                                            variants={listAnimation}
                                            initial="hidden"
                                            animate="visible"
                                            exit="hidden"
                                            custom={(indx + 1) * 0.4}
                                        >
                                            <div className="arrow">
                                                <img src={arrow} alt=""/>
                                            </div>
                                            <div className="address">
                                                {elem.title}
                                            </div>
                                            <div
                                                className="preview_img"
                                                style={{
                                                    background: `url(${elem.photo}) no-repeat`,
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center"

                                                }}
                                            />
                                            {/*<img
                                                className="preview_img"
                                                src={elem.photo}
                                                alt="photo"
                                            />*/}
                                        </motion.li>
                                    )}
                            </AnimatePresence>
                        </motion.ul>
                    </main>
                </div>
            </div>
            <div className="built_objects-mobile" ref={refPageMobile}>
                <div className="h1_title">
                    <span>Построенные</span>
                    <span>объекты</span>
                </div>
                <div className="slider_section" ref={refSliderContainer}>
                    <MobileSlider
                        data={data}
                        refSlider={refSliderMobile}
                        setActiveSlide={setActiveSlide}
                        isBuildObject={true}
                        setOpenModal={setOpenModal}
                        key={"isBuildObject"}
                        sliderHeight={sliderHeight}
                    />
                    <Dots
                        data={data}
                        refSlider={refSliderMobile}
                        setActiveSlide={(indx) => {
                            setActiveSlide(indx)
                        }}
                        activeSlide={activeSlide}
                    />
                </div>
            </div>
        </>
    );
};

export default BuildObjects;