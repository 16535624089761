import React from 'react';
import InputMask from "react-input-mask";
import cn from "classnames";
import './FeedbackForm.scss';

const FeedbackForm = (props) => {
    const {
        name, phone, message, setMessage, setPhone,
        setName, btnText, requestSend
    } = props;

    return (
        <div className="request_form">
            <div className="contact_row">
                <input
                    className="input_form"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder={"Имя"}
                    type="text"
                />
                <InputMask
                    className={"phone_mask"}
                    value={phone}
                    alwaysShowMask={true}
                    onChange={(e) => setPhone(e.target.value)}
                    mask={"+7 (999) 999-99-99"}
                />
            </div>
            <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={"Сообщение"}
                rows={2}
            />
            <div
                className={cn("btn_request",
                    {"disabled": !name || !message || phone.includes("_")}
                )}
                onClick={requestSend}
            >
                <span>{btnText}</span>
            </div>
        </div>
    );
};

export default FeedbackForm;