import React, { useEffect, useRef, useState } from 'react';

import preview_year from '../../assets/preview/preview_year.png';
import logo from '../../assets/preview/logo.svg';
import ball from '../../assets/preview/ball.png';
import arrow from '../../assets/preview/arrow.svg';
import arrow_mobile from '../../assets/preview/arrow_mobile.svg';
import logo_big from '../../assets/preview/logo_big.svg';
import './Preview.scss';
import helpFunctions from "../../helpers/helpFunctions";

const Preview = ({setOpenModal, activeSection, setCurrentColorHeader, scrollToAnchor}) => {
    const [timeoutState, setTimeoutState] = useState(null);
    const [classesAnimation, setClassesAnimation] = useState({
        image: false,
        top_year: false,
        bottom_year: false,
        bottom_text: false,

        logo: false,
        info: false,
        ball: false,
        arrow: false,
        bottom_span: false,
    });
    const text = "В 2013 году за многолетний плодотворный труд, значительный вклад в развитие строительной отрасли\n" +
        "Республики Саха (Якутия) и в связи с 60-летием образования профессионального предприятия в\n" +
        "Мегино-Кангаласском улусе Производственный кооператив «Мегинострой» награжден почетным знаком\n" +
        "«Строительная слава»."

    const refPageMobile = useRef();
    let timeoutFunc = (current, value, timeout) => {
        setTimeoutState(
            setTimeout(() => {
                setClassesAnimation(prev => ({...prev, [current]: value}))
            }, timeout)
        )
    }
    let eventScroll = () => {
        helpFunctions.isScrolledIntoHeader(
            refPageMobile.current,
            "preview_color",
            setCurrentColorHeader
        )
    }
    useEffect(() => {
        if (window.innerWidth < 991) {
            window.addEventListener("scroll", eventScroll);
            return () =>
                window.removeEventListener("scroll", eventScroll);
        }
    }, [])
    useEffect(() => {
        if (scrollToAnchor)
            refPageMobile.current.scrollIntoView({block: "start", behavior: "smooth"})
    }, [scrollToAnchor])
    useEffect(() => {
        // кастомная анимация
        if (activeSection === 0) {
            /* left col */
            timeoutFunc("image", true, 2100)
            timeoutFunc("top_year", true, 0)
            timeoutFunc("bottom_year", true, 800)
            timeoutFunc("bottom_text", true, 500)
            /* right col */
            timeoutFunc("info", true, 600)
            timeoutFunc("bottom_span", true, 800)
            timeoutFunc("logo", true, 1500)
            timeoutFunc("ball", true, 1700)
            timeoutFunc("arrow", true, 1900)
        }
        /*else {
            if (timeoutState !== null)
                clearTimeout(timeoutState);
            setClassesAnimation({});
        }*/
    }, [activeSection])
    return (
        <>
            <div className="section preview full_height-section">
                <div className="container">
                    <main>
                        <div className="left_col">
                            <img
                                src={preview_year}
                                alt="image"
                                className={`image ${classesAnimation.image}`}
                            />
                            <div className={`top_year ${classesAnimation.top_year}`}>
                                лет
                            </div>
                            <div className={`bottom_year ${classesAnimation.bottom_year}`}>
                                1953
                            </div>
                            <div className={`bottom_text ${classesAnimation.bottom_text}`}>
                                Год основания
                            </div>
                        </div>
                        <div className="middle_col"/>
                        <div className="right_col">
                            <div className="top_section">
                                <div
                                    className={`logo ${classesAnimation.logo}`}
                                    style={{
                                        background: `url(${logo_big}) no-repeat`,
                                        backgroundPosition: "center",
                                        backgroundSize: "100% 100%"
                                    }}
                                >
                                    <div className={`info ${classesAnimation.info}`}>
                                        Мегинострой <br/>
                                        ПРОИЗВОДСТВЕННЫЙ
                                        КООПЕРАТИВ
                                    </div>
                                </div>

                                <img
                                    src={ball}
                                    alt="ball"
                                    className={`ball ${classesAnimation.ball}`}
                                />
                            </div>
                            <div className="bottom_section">
                                <div
                                    className={`arrow ${classesAnimation.arrow}`}
                                    onClick={() => setOpenModal("preview_modal")}
                                >
                                    <img src={arrow} alt="arrow"/>
                                </div>
                                <div className={`bottom_span ${classesAnimation.bottom_span}`}>
                                    {text}
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            <div className="preview_mobile" ref={refPageMobile}>
                <div className="gradient"/>
                <main className="main">
                    <div className="logo">
                        <img src={logo} alt="logo"/>
                    </div>
                    <div className="ball">
                        <img src={ball} alt="ball"/>
                    </div>
                    <div className="info">
                        ПРОИЗВОДСТВЕННЫЙ
                        КООПЕРАТИВ
                    </div>
                    <div
                        className="arrow"
                        onClick={() => setOpenModal("preview_modal")}
                    >
                        <img src={arrow_mobile} alt="arrow_mobile"/>
                    </div>
                </main>
                <div className="bottom_info">
                    <div className="text">
                        {text}
                    </div>
                    <div className="preview_year">
                        <img src={preview_year} alt="preview_year"/>
                        <div className="top_year">лет</div>
                        <div className="bottom_year">1953</div>
                        <div className="bottom_text">год основания</div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default Preview;