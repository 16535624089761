import React, { useEffect, useState } from 'react';

import './VacancyModal.scss';
import FeedbackForm from "../../../components/feedback_form/FeedbackForm";
import requests from "../../../axios/requests";
import { message as message_antd } from "antd";

const VacancyModal = ({currentItemModal}) => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [data, setData] = useState({});

    /* const dataRequirements = [
         {label: "Требования к специалисту:", value: "Высшее, инженер теплотехник"},
         {label: "Заработная плата, руб:", value: "От 45000"},
         {label: "Потребность в работниках, чел:", value: "1"},
         {label: "Жилье/соц.пакет:", value: "Нет/Да"},
     ];*/
    /* const dataDocs = [
         "Трудовая книжка",
         "Паспорт Военный билет (справка с военкомата)",
         "ИНН-свидетельство",
         "СНИЛС-страховое свидетельство",
         "ПФ Документ об образовании (диплом, аттестат)",
         "Семейная справка",
         "Справка о зарплате предыдущего места работы за 2 года",
         "Свидетельство о рождении детей",
         "Свидетельство о браке"
     ];*/
    let clearState = () => {
        setName("");
        setPhone("");
        setMessage("");
    }
    let requestSend = () => {
        let obj = {
            vacancy: currentItemModal?.id,
            name,
            phone,
            message
        };
        if (!obj.vacancy || !name || !message || phone.includes("_"))
            return

        requests.create_application_vacancy(obj)
            .then(() => message_antd.success("Заявка успешно отправлена."))
            .catch(err => {
                message_antd.error("Ошибка заказа услуги.")
                console.error(err)
            })
            .finally(() => clearState())
    };
    useEffect(() => {
        requests.get_item_id("vacancy", currentItemModal?.id)
            .then(res => setData(res.data))
            .catch(err => console.error(err))
    }, [])
    return (
        <div>
            <div className="h1">{data?.name}</div>
            {/*<div className="info">{data?.info}</div>*/}
            {data?.description &&
                <div className="__html" dangerouslySetInnerHTML={{__html: data?.description}}/>
            }
            {/* <ul className="list_requirements list_style">
                {dataRequirements.map((elem, indx) =>
                    <li key={indx}>
                        <span>{elem.label}</span>
                        <span>{elem.value}</span>
                    </li>
                )}
            </ul>
            <div className="h1">Документы, требуемые при поступлении на работу</div>
            <div className="text">
                У нас свой автопарк. Ранее коллектив автопарка насчитывал более 50 человек, сейчас в результате
                оптимизации работает 14 человек механизаторов. Ежегодно стараемся обновить транспортные средства. На
                балансе предприятия числятся всего 19 техник в том числе:
            </div>
            <ul className="list_docs list_style">
                {dataDocs.map((elem, indx) =>
                    <li key={indx}>
                        <span>{indx + 1}.</span>
                        <span>{elem}</span>
                    </li>
                )}
            </ul>*/}
            <FeedbackForm
                name={name}
                phone={phone}
                message={message}
                setName={setName}
                setPhone={setPhone}
                setMessage={setMessage}
                requestSend={requestSend}
                btnText={"Оставить заявку"}
            />
        </div>
    );
};

export default VacancyModal;