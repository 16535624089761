import React, { useEffect } from 'react';
import Slider from "react-slick";
import cn from 'classnames';

import img from "../../assets/planetObjects/image_slider.png";
import arrow from "../../assets/planetObjects/arrow_modal.svg";
import arrow_2 from "../../assets/planetObjects/arrow_2.svg";
import './SliderComponent.scss';

const SliderComponent = (props) => {
    const {
        refSlider, imagesData, changeSlide, selectItem,
        isModal = true, speed = 1000, needCursor = false
    } = props;
    const settings = {
        dots: false,
        infinite: true,
        adaptiveHeight: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "slider_images",
        speed,
    };
    return (
        <>
            {imagesData && imagesData.length > 0 &&
                <div className="slider_section">
                    <Slider
                        ref={refSlider}
                        beforeChange={(_, indx) => {
                            if (typeof changeSlide === "function")
                                changeSlide(indx)
                        }}
                        {...settings}
                    >
                        {imagesData.map((elem, indx) =>
                            <div key={indx}>
                                <div
                                    className="inner_img"
                                    onClick={() => {
                                        if (typeof selectItem === "function")
                                            selectItem();
                                    }}
                                    style={needCursor ? {cursor: "pointer"} : {}}
                                >
                                    <img
                                        src={elem?.file || elem}
                                        alt="img"
                                        key={indx}
                                    />
                                </div>
                            </div>
                        )}
                    </Slider>
                    <div className={cn("arrows_slider", {
                        "modal": isModal,
                        "hide": !isModal
                    })}
                    >
                        <div onClick={() => refSlider?.current?.slickPrev()}>
                            <img src={arrow} alt="arrow}"/>
                        </div>
                        <div onClick={() => refSlider?.current?.slickNext()}>
                            <img src={arrow} alt="arrow}"/>
                        </div>
                    </div>
                    <div
                        className={cn("arrows_slider", {
                            "page": !isModal,
                            "hide": isModal
                        })
                        }
                        onClick={() => {
                            if (needCursor && typeof selectItem === "function") {
                                selectItem();
                            }
                        }}
                        style={(needCursor && typeof selectItem === "function")
                            ? {cursor: "pointer"}
                            : {}
                        }
                    >
                        <div
                            onClick={(event) => {
                                event.stopPropagation()
                                refSlider?.current?.slickPrev()
                            }}
                        >
                            <img src={arrow_2} alt="arrow_2}"/>
                        </div>
                        <div
                            onClick={(event) => {
                                event.stopPropagation()
                                refSlider?.current?.slickNext()
                            }}
                        >
                            <img src={arrow_2} alt="arrow_2}"/>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};

export default SliderComponent;