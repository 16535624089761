import React, { useEffect } from 'react';
import Slider from "react-slick";
import helpFunctions from "../../helpers/helpFunctions";

import img from "../../assets/planetObjects/image_slider.png";
import arrow from "../../assets/news/arrow.svg";
import arrow_s from "../../assets/news/arrow_s.svg";
import './MobileSlider.scss';

const MobileSlider = (props) => {
    const {
        data, setOpenModal, refSlider, setActiveSlide, selectItem,
        anotherRef, isPlanetObject = false, isService = false,
        isBuildObject = false, isNews = false, isNewsChildren = false,
        sliderHeight = 0
    } = props;

    const settings = {
        dots: false,
        infinite: true,
        adaptiveHeight: false,
        centerPadding: '0',
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "slider_mobile-service",
        speed: 1000,
        id: 'slidercomponent-id'
    };
    return (
        <Slider
            ref={refSlider}
            beforeChange={(_, indx) => setActiveSlide(indx)}
            id={"slidercomponent-id"}
            {...settings}
        >
            {isPlanetObject && data.length > 0 &&
                data.map((elem, indx) =>
                    <div key={indx}>
                        <div
                            className="inner_img"
                            onClick={() => {
                                if (typeof selectItem === "function")
                                    selectItem();
                            }}
                            style={{
                                background: `url(${elem}) no-repeat`,
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                minHeight: 225,
                            }}
                        />
                    </div>
                )
            }

            {isService && data.length > 0 &&
                data.map((elem, indx) =>
                    <div
                        key={indx}
                        style={{marginRight: "10px"}}
                    >
                        <div
                            className="slider_item"
                            onClick={() => setOpenModal("services_modal", elem)}
                        >
                            <div
                                className="machine_image"
                                style={{
                                    background: `url(${elem.photo}) no-repeat`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "40% 50%"
                                }}
                            />
                            <div className="info">
                                <div className="name">
                                    {elem.name}
                                </div>
                                {elem.price &&
                                    <div className="cost">
                                        {elem?.price_type === "passage"
                                            ? <span>{elem.price} р/рейс</span>
                                            : <span>{elem.price} р/час</span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )
            }

            {isBuildObject && data.length > 0 &&
                data.map((elem, indx) =>
                    <div key={indx}>
                        <li
                            onClick={() => setOpenModal("build_object_modal", elem)}
                            style={{
                                minHeight:  sliderHeight ?  sliderHeight : "initial"
                            }}
                        >
                            <div className="arrow">
                                <img src={arrow} alt=""/>
                            </div>
                            <div className="address">
                                {elem.title}
                            </div>
                            <img
                                className="preview"
                                src={elem.photo}
                                alt="photo"
                            />
                        </li>
                    </div>
                )}
            {isNews && data.length > 0 &&
                data.map((elem, indx) =>
                    <div
                        key={indx}
                        style={{marginRight: "10px"}}
                    >
                        <div
                            className={"event_band"}
                            onClick={() => setActiveSlide(indx)}
                        >
                            <>
                                {data?.count
                                    ? <div className="count_news">
                                        Всего новостей : {data?.count}
                                    </div>
                                    : <></>
                                }
                                {indx === 0 ?
                                    <div className="label">
                                        лЕНта информационных СОБЫТИЙ
                                    </div>
                                    : <div className="label">
                                        новости из мира стоительства
                                    </div>
                                }
                            </>
                            <div className="arrows">
                                <div
                                    className="arrow"
                                    onClick={(event) => {
                                        anotherRef?.current?.slickPrev()
                                    }}
                                >
                                    <img src={arrow} alt="arrow"/>
                                </div>
                                <div
                                    className="arrow"
                                    onClick={() => anotherRef?.current?.slickNext()}
                                >
                                    <img src={arrow} alt="arrow"/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {isNewsChildren && data?.length > 0 &&
                data.map((elem, indx) =>
                    <div key={indx}>
                        <div
                            className="news_elem"
                            onClick={() => setOpenModal("news_modal", elem)}
                        >
                            <div className="date_row">
                                <div className="title">
                                    {helpFunctions.dateToObj(elem.date).month}
                                </div>
                                <div className="year">
                                    <span>
                                        {helpFunctions.dateToObj(elem.date).year}
                                    </span>
                                    <div className="arrow">
                                        <img
                                            src={arrow_s}
                                            alt="arrow_s"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="preview_img"
                                style={{
                                    background: `url(${elem.image}) no-repeat`,
                                    backgroundPosition: "50% 50%",
                                    backgroundSize: "contain"
                                }}
                            />

                            <div className="label">
                                {elem.title}
                            </div>
                        </div>
                    </div>
                )
            }
        </Slider>
    );
};

export default MobileSlider;