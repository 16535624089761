import React, { useEffect, useRef, useState } from 'react';
import Header from "../../components/Header/Header";

import figure from '../../assets/contacts/contact_figure.png';
import house from '../../assets/contacts/house.png';
import phone from '../../assets/contacts/phone.svg';
import mail from '../../assets/contacts/mail.svg';
import location from '../../assets/contacts/location.svg';
import download from '../../assets/contacts/download.svg';
import './Contacts.scss';
import { motion } from "framer-motion";
import requests from "../../axios/requests";
import helpFunctions from "../../helpers/helpFunctions";

const Contacts = ({activeSection, setCurrentColorHeader, scrollToAnchor}) => {
    const [showListAnimation, setShowListAnimation] = useState(false);

    const refPageMobile = useRef();
    const h1_animation = {
        hidden: {opacity: 0},
        visible: (custom) => {
            if (showListAnimation)
                return {
                    opacity: 1,
                    transition: {
                        duration: 2,
                        delay: custom || 0.2
                    }
                }
        }
    };

    let eventScroll = () => {
        helpFunctions.isScrolledIntoHeader(
            refPageMobile.current,
            "contacts_color",
            setCurrentColorHeader
        )
    }

    useEffect(() => {
        if (window.innerWidth < 991) {
            window.addEventListener("scroll", eventScroll);
            return () =>
                window.removeEventListener("scroll", eventScroll);
        }
    }, [])
    useEffect(() => {
        if (activeSection === 8)
            setShowListAnimation(true);
    }, [activeSection])
    useEffect(() => {
        if (scrollToAnchor)
            refPageMobile.current.scrollIntoView({block: "start", behavior: "smooth"})
    }, [scrollToAnchor])
    return (
        <>
            <div className="section contacts full_height-section">
                <div className="container">
                    <motion.div
                        className="h1"
                        variants={h1_animation}
                        initial="hidden"
                        animate="visible"
                    >
                        Контакты компании
                    </motion.div>
                    <main>
                        <div className="map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d240644.38194897684!2d129.67617334674839!3d61.88726221995603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5bf7c1947d678e95%3A0xb37d317b07eaffe9!2z0KDQvtGB0LPQvtGB0YHRgtGA0LDRhQ!5e0!3m2!1sru!2sru!4v1684142924870!5m2!1sru!2sru"
                                allowFullScreen=""
                                style={{border: "none"}}
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            />
                        </div>
                        <div className="feedback">
                            <motion.div
                                className="h3"
                                variants={h1_animation}
                                initial="hidden"
                                animate="visible"
                            >
                                связи с нами
                            </motion.div>
                            <motion.ul
                                className="list list_style"
                                initial="hidden"
                                animate="visible"
                                variants={h1_animation}
                                custom={1}
                            >
                                <li>
                                    <div className="icon">
                                        <img src={phone} alt="phone"/>
                                    </div>
                                    <span>+7 914 103-69-94 Бухгалтерия</span>
                                </li>
                                <li>
                                    <div className="icon">
                                        <img src={mail} alt="mail"/>
                                    </div>
                                    <span>pkmeginostroy@mail.ru</span>
                                </li>
                                <li>
                                    <div className="icon">
                                        <img src={location} alt="location"/>
                                    </div>
                                    <span>
                                    Республика Саха (Якутия), Мегино-Кангаласский улус, с. Майя, ул. Докторова Н.П., д. 29
                                </span>
                                </li>
                            </motion.ul>
                            <a
                                href={"/card_company.docx"}
                                className="download"
                                download={true}
                            >
                                <img src={download} alt="download"/>
                                <span>Скачать карточку организации</span>
                            </a>
                            <img src={figure} alt="figure" className="figure"/>
                            <img src={house} alt="house" className="bttm_img"/>
                        </div>
                    </main>
                </div>
            </div>
            <div className="contacts_mobile" ref={refPageMobile}>
                <div className="h1_title">
                    <span>контакты </span>
                    <span>компании</span>
                </div>
                <div className="map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d240644.38194897684!2d129.67617334674839!3d61.88726221995603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5bf7c1947d678e95%3A0xb37d317b07eaffe9!2z0KDQvtGB0LPQvtGB0YHRgtGA0LDRhQ!5e0!3m2!1sru!2sru!4v1684142924870!5m2!1sru!2sru"
                        allowFullScreen=""
                        style={{border: "none"}}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    />
                    <div className="feedback">
                        <div className="h3">
                            связи с нами
                        </div>
                        <ul className="list list_style">
                            <li>
                                <div className="icon">
                                    <img src={phone} alt="phone"/>
                                </div>
                                <span>+7 914 103-69-94 Бухгалтерия</span>
                            </li>
                            <li>
                                <div className="icon">
                                    <img src={mail} alt="mail"/>
                                </div>
                                <span>pkmeginostroy@mail.ru</span>
                            </li>
                            <li>
                                <div className="icon">
                                    <img src={location} alt="location"/>
                                </div>
                                <span>
                                    Республика Саха (Якутия), Мегино-Кангаласский улус, с. Майя, ул. Докторова Н.П., д. 29
                                </span>
                            </li>
                        </ul>
                        <a
                            href={"/card_company.docx"}
                            className="download"
                            download={true}
                        >
                            <img src={download} alt="download"/>
                            <span>Скачать карточку организации</span>
                        </a>
                        <img src={house} alt="house" className="bttm_img"/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contacts;