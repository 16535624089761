import React, { useEffect, useState } from 'react';

import './OrdersModal.scss';
import FeedbackForm from "../../../components/feedback_form/FeedbackForm";
import requests from "../../../axios/requests";
import { message as message_antd } from "antd";

const OrdersModal = ({currentItemModal}) => {
    const [data, setData] = useState({});
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    let clearState = () => {
        setName("");
        setPhone("");
        setMessage("");
    }
    let requestSend = () => {
        let obj = {
            service: currentItemModal?.id,
            name,
            phone,
            message
        };
        if (!obj.service || !name || !message || phone.includes("_"))
            return
        requests.create_application_service(obj)
            .then(() => message_antd.success("Заказ на услугу успешно отправлен."))
            .catch(err => {
                message_antd.error("Ошибка заказа услуги.")
                console.error(err)
            })
            .finally(() => clearState())
    };

    useEffect(() => {
        requests.get_item_id("service",currentItemModal.id)
            .then(res => setData(res.data))
    }, [])
    return (
        <>
            <div className="h1">
                {data?.name}
            </div>
            {/*<img
                src={data?.photo}
                className="image"
                alt="request"
            />*/}
            {data?.description &&
                <div className="__html" dangerouslySetInnerHTML={{__html: data.description}}/>
            }
            <FeedbackForm
                name={name}
                phone={phone}
                message={message}
                setName={setName}
                setPhone={setPhone}
                setMessage={setMessage}
                requestSend={requestSend}
                btnText={"Заказать услугу"}
            />
        </>
    );
};

export default OrdersModal;