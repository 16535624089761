import React, { useEffect, useState } from 'react';
import requests from "../../../axios/requests";

import './PhotoGaleryWorkDays.scss';

const PhotoGaleryWorkDays = ({currentItemModal}) => {
    const [data, setData] = useState({});

    useEffect(() => {
        requests.get_item_id("photo_album", currentItemModal?.id)
            .then(res => setData(res.data))
            .catch(err => console.error(err))
    }, [])

    return (
        <>
            {data?.date &&
                <div className="date">{data.date}</div>
            }
            <div className={`current_galery`}>
                <div className="name">{data?.name}</div>
                {Object.values(data).length > 0 &&
                    data.photos.map((elem, indx) =>
                        <img
                            src={elem.file}
                            alt="current_galery"
                            key={indx}
                        />
                    )}
            </div>
        </>
    );
};

export default PhotoGaleryWorkDays;