import React, { useEffect, useRef, useState } from 'react';
import { Transition } from "react-transition-group";
import classNames from "classnames";

import close from '../../assets/close.svg';
import './ModalContainer.scss';
import helpFunctions from "../../helpers/helpFunctions";


const ModalContainer = ({openModal, closeModal, currentModal, children}) => {
    const [scrollY, setScrollY] = useState(null);

    let handleScroll = (event) =>
        setScrollY(event?.currentTarget?.scrollTop);

    return (
        <Transition
            in={openModal}
            timeout={400}
            unmountOnExit
            mountOnEnter
        >
            {state =>
                <div className={`modal_parent ${state} ${currentModal}`}>
                    <div
                        className={classNames(`modal_wrapper ${state} ${currentModal}`, {
                            "firefox": helpFunctions.detectBrowserUser().includes("Firefox")
                        })}
                        onScroll={handleScroll}
                    >
                        <div
                            className={scrollY > 50 ? "close hide" : "close"}
                            onClick={closeModal}
                        >
                            <img src={close} alt="close"/>
                        </div>
                        {children}
                    </div>
                    <div
                        className={scrollY > 50 ? "close_2 active" : "close_2"}
                        onClick={closeModal}
                    >
                        <img src={close} alt="close"/>
                    </div>
                </div>
            }
        </Transition>

    );
};

export default ModalContainer;