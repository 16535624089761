import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import requests from "../../axios/requests";

import { ReactComponent as Arrow } from '../../assets/order/arrow.svg';
import './Orders.scss';
import helpFunctions from "../../helpers/helpFunctions";

const Orders = ({setOpenModal, activeSection, setCurrentColorHeader, scrollToAnchor}) => {
    const [data, setData] = useState([]);
    const [activeImage, setActiveImage] = useState("");
    const [imageStyle, setImageStyle] = useState("");
    const [activeNumImage, setActiveNumImage] = useState("");
    const [timeoutChangeImage, setTimeoutChangeImage] = useState(null);
    const [showListAnimation, setShowListAnimation] = useState(false);

    const listAnimation = {
        hidden: {opacity: 0},
        visible: (custom) => {
            if (showListAnimation)
                return {
                    opacity: 1,
                    transition: {delay: custom}
                }
        }
    }
    const refPageMobile = useRef();

    let showNumIndex = (num) => {
        if (num < 10)
            return "0" + num
        return num
    }
    let eventScroll = () => {
        helpFunctions.isScrolledIntoHeader(
            refPageMobile.current,
            "orders_color",
            setCurrentColorHeader
        )
    }

    useEffect(() => {
        requests.fetch_services_reqular("regular", 1, 20)
            .then(res => setData(res.data.results))
            .catch(err => console.error(err))

        if (window.innerWidth < 991) {
            window.addEventListener("scroll", eventScroll);
            return () =>
                window.removeEventListener("scroll", eventScroll);
        }
    }, [])
    useEffect(() => {
        if (data.length > 0) {
            setActiveImage(data[0].photo);
            setActiveNumImage("01");
        }
    }, [data])
    useEffect(() => {
        if (activeSection === 2)
            setShowListAnimation(true);
    }, [activeSection])
    useEffect(() => {
        if (scrollToAnchor)
            refPageMobile.current.scrollIntoView({block: "start", behavior: "smooth"})
    }, [scrollToAnchor])
    return (
        <>
            <div className="section orders full_height-section">
                <div className="container">
                    <div className="left_col">
                        <div className="h1">услуги</div>
                        <div className="preview_image">
                            {activeImage &&
                                <>
                                    <img
                                        src={activeImage}
                                        className={`active_image ${imageStyle}`}
                                        alt="house"
                                    />
                                    <div className="number">
                                        <span>{activeNumImage}</span>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <main>
                        <div className="right_col">
                            <motion.ul className="list list_style">
                                <AnimatePresence>
                                    {data.length > 0 &&
                                        data.map((elem, indx) =>
                                            <motion.li
                                                key={elem.id}
                                                variants={listAnimation}
                                                initial="hidden"
                                                animate="visible"
                                                exit="hidden"
                                                custom={(indx + 1) * 0.3}
                                                onClick={() => setOpenModal("orders_modal", elem)}
                                                onMouseEnter={() => {
                                                    if (activeImage !== elem.photo) {
                                                        if (timeoutChangeImage)
                                                            clearTimeout(timeoutChangeImage)
                                                        setImageStyle("opacity");
                                                        setTimeoutChangeImage(
                                                            setTimeout(() => {
                                                                setActiveImage(elem.photo)
                                                                setActiveNumImage(showNumIndex(indx + 1))
                                                                setImageStyle("");
                                                            }, 500)
                                                        )
                                                    }
                                                }}
                                            >
                                                <div className="index">{showNumIndex(indx + 1)}</div>
                                                <div className="figure"/>
                                                <div className="text">{elem.name}</div>
                                                <div
                                                    className="order_btn"
                                                >
                                                    <span>Заказать услугу</span>
                                                    <div className="arrow">
                                                        <Arrow/>
                                                    </div>
                                                </div>
                                            </motion.li>
                                        )}
                                </AnimatePresence>
                            </motion.ul>
                            <ul className="list list_style">

                            </ul>
                            {/*</div>*/}
                        </div>
                    </main>
                </div>
            </div>
            <div className="orders_mobile" ref={refPageMobile}>
                <div className="preview_section">
                    <div className="h1_title">
                        услуги
                    </div>
                    {/* {activeImage &&
                        <div className="preview_image">
                            <img
                                src={activeImage}
                                alt="activeImage"
                                className={`active_image ${imageStyle}`}
                            />
                            <div className="number">
                                <span>{activeNumImage}</span>
                            </div>
                        </div>
                    }*/}
                </div>
                <div className="main">
                    <ul className="list list_style">
                        {data.length > 0 && data.map((elem, indx) =>
                            <li
                                onClick={() => setOpenModal("orders_modal", elem)}
                                /*onMouseEnter={() => {
                                    if (activeImage !== elem.photo) {
                                        if (timeoutChangeImage)
                                            clearTimeout(timeoutChangeImage)
                                        setImageStyle("opacity");
                                        setTimeoutChangeImage(
                                            setTimeout(() => {
                                                setActiveImage(elem.photo)
                                                setActiveNumImage(showNumIndex(indx + 1))
                                                setImageStyle("");
                                            }, 500)
                                        )
                                    }
                                }}*/
                                key={indx}
                            >
                                <div className="index">{showNumIndex(indx + 1)}</div>
                                <div className="figure"/>
                                <div className="text">{elem.name}</div>
                                <div
                                    className="order_btn"
                                >
                                    <div className="arrow">
                                        <Arrow/>
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </>
    )
};

export default Orders;