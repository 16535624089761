import React, { useEffect, useRef, useState } from 'react';
import requests from "../../axios/requests";
import { message } from "antd";

import './PhotoGalery.scss';
import helpFunctions from "../../helpers/helpFunctions";

const PhotoGalery = ({setOpenModal, setCurrentColorHeader, scrollToAnchor}) => {
    const [dataAblums, setDataAlbums] = useState([]);
    const [workDays, setWorkDays] = useState({});

    const refPageMobile = useRef();
    let eventScroll = () => {
        helpFunctions.isScrolledIntoHeader(
            refPageMobile.current,
            "albums_color",
            setCurrentColorHeader
        )
    }

    useEffect(() => {
        requests.fetch_photo_album(1, 100)
            .then(res => {
                const data = res.data.results;
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++)
                        if (data[i].name === "Рабочие будни")
                            setWorkDays(data[i])
                }
                setDataAlbums(res.data.results)
            })
            .catch(err => console.error(err))
        if (window.innerWidth < 991) {
            window.addEventListener("scroll", eventScroll);
            return () =>
                window.removeEventListener("scroll", eventScroll);
        }
    }, [])
    useEffect(() => {
        if (scrollToAnchor)
            refPageMobile.current.scrollIntoView({block: "start", behavior: "smooth"})
    }, [scrollToAnchor])
    return (
        <>
            <div className="section galery">
                <div className="container">
                    <main>
                        <div className="checked_row">
                            <div onClick={() => setOpenModal("photo_galery_modal", dataAblums)}>
                                <span>фотоальбомы</span>
                            </div>
                            <div onClick={() => {
                                if (workDays?.id)
                                    setOpenModal("photo_work_days_modal", workDays);
                                else
                                    message.info("Альбом \"Рабочие будни\" отсутствует")
                            }}>
                                <span>рабочие будни</span>
                            </div>
                        </div>
                        <div className="border" />
                        <div className="h1">Фотогалерея</div>
                    </main>
                </div>
            </div>
            <div className="galery_mobile" ref={refPageMobile}>
                <div className="checked_row">
                    <div onClick={() => setOpenModal("photo_galery_modal", dataAblums)}>
                        <span>фотоальбомы</span>
                    </div>
                    <div onClick={() => {
                        if (workDays?.id)
                            setOpenModal("photo_work_days_modal", workDays);
                        else
                            message.info("Альбом \"Рабочие будни\" отсутствует")
                    }}>
                        <span>рабочие будни</span>
                    </div>
                </div>
                <div className="border" />
                <div className="h1">
                    <span>фото</span>
                    <span>галерея</span>
                </div>
            </div>
        </>
    );
};

export default PhotoGalery;