import React, { useState } from 'react';
import { message as message_antd } from "antd";
import FeedbackForm from "../../../components/feedback_form/FeedbackForm";

import './ServicesModal.scss';
import requests from "../../../axios/requests";

const ServicesModal = ({currentItemModal}) => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    let clearState = () => {
        setName("");
        setPhone("");
        setMessage("");
    }
    let requestSend = () => {
        let obj = {
            ...(currentItemModal?.id ? {service: currentItemModal?.id} : {}),
            name,
            phone,
            message
        };
        if (!name || !message || phone.includes("_"))
            return

        requests.create_application_service(obj)
            .then(() => message_antd.success("Заявка успешно отправлена."))
            .catch(err => {
                message_antd.error("Ошибка заказа услуги.")
                console.error(err)
            })
            .finally(() => clearState())
    };
    return (
        <>
            {currentItemModal?.price &&
                <div className="price">
                    <span>{currentItemModal.price} р/час</span>
                </div>
            }

            <div className="h1">
                {currentItemModal?.name}
            </div>
            {currentItemModal?.photo &&
                <img
                    src={currentItemModal?.photo}
                    alt="image"
                    className="preview_img-item"
                />
            }
            {currentItemModal?.description &&
                <div className="__html" dangerouslySetInnerHTML={{__html: currentItemModal.description}}/>
            }
            <FeedbackForm
                name={name}
                phone={phone}
                message={message}
                setName={setName}
                setPhone={setPhone}
                setMessage={setMessage}
                requestSend={requestSend}
                btnText={(!currentItemModal?.price && !currentItemModal?.photo)
                    ? "Оставить заявку"
                    : "Заказать услугу"
                }
            />
        </>
    );
};

export default ServicesModal;