import React from 'react';

import medal from '../../../assets/preview/str_sl.jpg';
import sertificat from '../../../assets/preview/sertificat.jpg';
import supervisor from '../../../assets/preview/supervisor.png';
import engineer from '../../../assets/preview/engineer.jpg';
import './PreviewModal.scss';

export const Preview = () => {
    const customers = [
        "ГКУ «Служба государственного заказчика Республики Саха (Якутия)",
        "ООО ИСФ «Дирекция по строительству",
        "ГКУ «Дирекция жилищного строительства Республики Саха (Якутия)",
        "Районная администрация муниципального района «Мегино-Кангаласский улус",
        "Аминистрация муниципального района «Амгинский улус",
        "Целевой фонд будущих поколений Республики Саха (Якутия)",
    ];
    const supervisors = [
        {post: "Председатель", name: "Докторов Николай Святославович", src: supervisor},
        {post: "Главный инженер", name: "Тастыгин Кирилл Егорович", src: engineer},
        {post: "Главный бухгалтер", name: "Попова Клавдия Николаевна", src: supervisor},
    ];
    const balance = [
        "Строительная техника – 2 бетоносмесительные техники на базе КАМАЗ, 1 самоходная бетоносмесительная установка китайского производства, 1 самосвал КАМАЗ-5511, 2 фронтальных погрузчика;",
        "Для грузоперевозок – седельный тягач МАЗ-6430, бортовой КАМАЗ 532020 с прицепом, а также 2 большегрузных самосвала FAW и SHAANXI;",
        "Подъемная спецтехника – 2 автокрана ИВАНОВЕЦ на базе КАМАЗ, МАЗ м 1 вилочный погрузчик;",
        "Дорожная спецтехника – бульдозер ДТ-75;",
        "Коммунальная спецтехника – ЗИЛ ММЗ 554 «Водовоз»",
        "Сельскохозяйственная техника- 2 трактора с навесным оборудованием МТЗ-87",
        "Грузопассажирский микроавтобус TOYOTA HILUX – 2012 года выпуска;",
        "Грузопассажирский микроавтобус УАЗ – 39099 2011 года выпуска.",
    ];
    return (
        <>
            <div className="label">
                <div className="group_row">
                    <div className="h1_figure">Мегинострой</div>
                    <span>пРОИЗВОДСТВЕННЫЙ КООПЕРАТИВ</span>
                </div>
            </div>
            <div className="h2">
                о компании
            </div>
            <div className="text">
                В Мегино-Кангаласском районе история первой государственной строительной
                организации, именуемой в настоящее время Производственный кооператив «Мегинострой», началась во
                второй половине прошлого века, когда на основании Постановления Совета Министрой ЯАССР № 164 от
                03.03.1953 г. решением Мегино-Кангаласского Исполкома районного Совета от 30.03.1953 г. организован
                Майинский строительный участок СМУ №2 треста «Якутстрой».
            </div>
            <div className="h2">
                награды
            </div>
            <div className="text">
                В 2013 году за многолетний плодотворный труд, значительный вклад в развитие строительной отрасли
                Республики Саха (Якутия) и в связи с 60-летием образования профессионального предприятия в
                Мегино-Кангаласском улусе Производственный кооператив «Мегинострой» награжден почетным знаком
                «Строительная слава».
            </div>
            <img src={medal} alt="medal" className="image"/>
            <img src={sertificat} alt="sertificat" className="image"/>
            <div className="h2">
                За годы существования наше предприятие завоевало доверие многих крупных заказчиков:
            </div>
            <ul className="list_customers list_style">
                {customers.map((elem, indx) =>
                    <li key={indx}>
                        <div className="figure"/>
                        <span>{elem}</span>
                    </li>
                )}
            </ul>
            <div className="h2">Руководители</div>
            <ul className="list_supervisiors list_style">
                {supervisors.map((elem, indx) =>
                    <li key={indx}>
                      {/*  <div className="img"
                             style={{
                                 background: `url(${elem.src}) no-repeat`,
                                 backgroundPosition: "20% 30%",
                                 backgroundSize: "100% 100%"
                             }}
                        />*/}
                        <img src={elem.src} alt={elem.name}/>
                        <div className="post">{elem.post}</div>
                        <div className="name">{elem.name}</div>
                    </li>
                )}
            </ul>
            <div className="h2">Состав компании</div>
            <div className="text static">
                Общая численность работающих: на постоянной основе - 56 человек: в бригаде каменщиков трудятся 17
                человек, в центральном гараже 14 человек, в котельной - 5 человек, руководители 9 человек, специалисты
                11 человек, договорников – 10 человек.
            </div>
            <div className="border"/>
            <div className="text static">
                За время работы нашего предприятия построена собственная производственная база, также собственный цех по
                производству стеновых блоков, кузнечно-токарный цех, столярный цех, гараж на 25 автомашин, котельная,
                пилорама, склады.
            </div>
            <div className="border"/>
            <div className="text static">
                У нас свой автопарк. Ранее коллектив автопарка насчитывал более 50 человек, сейчас в результате
                оптимизации работает 14 человек механизаторов. Ежегодно стараемся обновить транспортные средства. На
                балансе предприятия числятся всего 19 техник в том числе:
            </div>
            <ul className="list_balance list_style">
                {balance.map((elem, indx) =>
                    <li key={indx}>
                        <span>{indx + 1}.</span>
                        <span>{elem}</span>
                    </li>
                )}
            </ul>
        </>
    );
};

