import React, { useEffect, useRef, useState } from 'react';
import './NewsModal.scss';
import SliderComponent from "../../../components/Slider/SliderComponent";
import img from "../../../assets/planetObjects/image_slider.png";
import requests from "../../../axios/requests";

const NewsModal = ({currentItemModal}) => {
    const refSlider = useRef();
    const [data, setData] = useState({});

    useEffect(() => {
        requests.get_item_id("news", currentItemModal?.slug)
            .then(res => setData(res.data))
            .catch(err => console.error(err))
    }, [])
    return (
        <div>
            <div className="h1">{data?.title}</div>
            {data?.photos?.length > 0 &&
                <SliderComponent
                    imagesData={data.photos}
                    refSlider={refSlider}
                />
            }
            <div className="text">{data?.text}</div>
            {data?.post &&
                <div className="__html" dangerouslySetInnerHTML={{__html: data.post}}/>
            }
        </div>
    );
};

export default NewsModal;