import React, { useState } from 'react';
import classNames from "classnames";
import helpFunctions from "../../helpers/helpFunctions";
import { Transition } from "react-transition-group";

import menu_icon from '../../assets/header/menu_icon.svg';
import person_icon from '../../assets/header/person_icon.svg';
import burger from '../../assets/header/burger.svg';
import person from '../../assets/header/person.svg';
import close_burger from '../../assets/header/close_burger.svg';
import preview_img from '../../assets/header/preview_img.png';
import "./Header.scss";

const Header = ({currentSectionName, currentColorHeader, scrollToSection, scrollTo}) => {
    const [openDrop, setOpenDrop] = useState(false);
    const [openDropMobile, setOpenDropMobile] = useState(false);
    const list = [
        "О компании",
        "услуги спецтехники",
        "строительные услуги",
        "Фотогалерея",
        "Планируемые объекты",
        "Построенные объекты",
        "нОВОСТИ",
        "вАКАНСИИ",
        "кОНТАКТЫ",
    ]
    return (
        <>
            <div className={classNames(`header ${currentSectionName}`, {
                "firefox": helpFunctions.detectBrowserUser().includes("Firefox")
            })}>
                <div className="container">
                    <div className="navigation_row">
                        <div
                            className="menu"
                            onClick={() => setOpenDrop(!openDrop)}
                        >
                            <img src={openDrop ? close_burger : menu_icon} alt="menu_icon"/>
                            <span>Меню</span>
                        </div>

                        <div
                            className="service"
                            onClick={() => scrollToSection(2)}
                        >
                            <span> Услуги спецтехники </span>
                        </div>
                    </div>
                    <div className="logo">Мегинострой</div>
                    <div className="personal_account">
                        <div className="phone">
                            8 (41143) 4-16-34
                        </div>
                        <div className="person">
                            <img src={person_icon} alt="person_icon"/>
                            <span>Личный кабинет</span>
                        </div>
                    </div>
                    <Transition
                        in={openDrop}
                        timeout={600}
                        unmountOnExit
                        mountOnEnter
                    >
                        {(state) =>
                            <div className={`menu_drop ${state}`}>
                                <ul className="left_col list_style">
                                    {list.map((elem, indx) =>
                                        <li
                                            onClick={() => {
                                                scrollToSection(indx + 1)
                                                setTimeout(() =>
                                                    setOpenDrop(false), 10)
                                            }}
                                            key={indx}
                                        >
                                            {elem}
                                        </li>
                                    )}
                                </ul>
                                <div className="right_col">
                                    <img src={preview_img} alt="preview_img"/>
                                    <div className="year">1953</div>
                                </div>
                            </div>
                        }
                    </Transition>
                </div>
            </div>
            <div className={`header_mobile ${currentColorHeader}`}>
                <div className="inner">
                    <div
                        className="burger"
                        onClick={() => setOpenDropMobile(!openDropMobile)}
                    >
                        <img src={openDropMobile ? close_burger : burger} alt="burger"/>
                    </div>
                    <div className="logo">
                        Мегинострой
                    </div>
                    <div className="person">
                        <img src={person} alt="person"/>
                    </div>
                </div>
                <Transition
                    in={openDropMobile}
                    timeout={600}
                    unmountOnExit
                    mountOnEnter
                >
                    {(state) =>
                        <div className={`menu_drop ${state}`}>
                            <ul className="left_col list_style">
                                {list.map((elem, indx) =>
                                    <li
                                        onClick={() => {
                                            scrollTo(indx);
                                            setTimeout(() =>
                                                setOpenDropMobile(false), 10)
                                        }}
                                        key={indx}
                                    >
                                        {elem}
                                    </li>
                                )}
                            </ul>
                        </div>
                    }
                </Transition>
            </div>
        </>
    );
};

export default Header;