import React, { useEffect, useRef, useState } from 'react';
import requests from "../../../axios/requests";
import SliderComponent from "../../../components/Slider/SliderComponent";

import './BuildObjectsModal.scss';

const BuildObjectsModal = ({currentItemModal}) => {
    const [data, setData] = useState({});
    const refSlider = useRef();

    useEffect(() => {
        requests.get_item_id("project",currentItemModal?.id)
            .then(res => setData(res.data))
            .catch(err => console.error(err))
    }, [])
    return (
        <div>
            {Object.values(data).length > 0 &&
                <>
                    <div className="h1">{data?.title}</div>
                    {data?.photos?.length > 0 &&
                        <SliderComponent
                            refSlider={refSlider}
                            imagesData={data.photos}
                        />
                    }
                    <div className="text">{data?.short_description}</div>
                    {data?.description &&
                        <div className="__html" dangerouslySetInnerHTML={{__html: data.description}}/>
                    }
                </>
            }

        </div>
    );
};

export default BuildObjectsModal;