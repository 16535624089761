import React, { useEffect, useRef, useState } from 'react';
import { motion } from "framer-motion";
import requests from "../../axios/requests";

import Slider from "./Slider/Slider";
import MobileSlider from "../../components/MobileSlider/MobileSlider";
import Dots from "../../components/Dots/Dots";

import arrow from '../../assets/planetObjects/arrow.svg';
import './PlanetObjects.scss';
import helpFunctions from "../../helpers/helpFunctions";

const PlanetObjects = ({setOpenModal, activeSection, setCurrentColorHeader, scrollToAnchor}) => {
    const [data, setData] = useState([]);
    const [imagesSlider, setImagesSlider] = useState([]);
    const [currentObject, setCurrentObject] = useState({});
    const [activeStyle, setActiveStyle] = useState("");
    const [activeSlide, setActiveSlide] = useState(0);
    const [showListAnimation, setShowListAnimation] = useState(false);

    const refSlider = useRef();
    const refSliderMobile = useRef();
    const refPageMobile = useRef();
    const h1_animation = {
        hidden: {opacity: 0},
        visible: (custom) => {
            if (showListAnimation)
                return {
                    opacity: 1,
                    transition: {
                        duration: 2,
                        delay: custom || 0.8
                    }
                }
        }
    };

    let changeSlide = (indx) => {
        setActiveSlide(indx)
        setCurrentObject(data[indx]);
        setActiveStyle("opacity")
        setTimeout(() => {
            setActiveStyle("")
        }, 700)
    }
    let eventScroll = () => {
        helpFunctions.isScrolledIntoHeader(
            refPageMobile.current,
            "planned_color",
            setCurrentColorHeader
        )
    }

    useEffect(() => {
        requests.fetch_object("planned", 1, 10)
            .then(res => {
                const data = res.data.results;
                setData(data);
                if (data.length > 0) {
                    let imagesArr = [];
                    for (let i = 0; i < data.length; i++) {
                        imagesArr = [...imagesArr, data[i].photo]
                    }
                    setImagesSlider(imagesArr)
                    setCurrentObject(data[0]);
                }
            })
            .catch(err => console.error(err))

        if (window.innerWidth < 991) {
            window.addEventListener("scroll", eventScroll);
            return () =>
                window.removeEventListener("scroll", eventScroll);
        }
    }, [])
    useEffect(() => {
        if (activeSection === 4)
            setShowListAnimation(true);
    }, [activeSection])
    useEffect(() => {
        if (scrollToAnchor)
            refPageMobile.current.scrollIntoView({block: "start", behavior: "smooth"})
    }, [scrollToAnchor])
    return (
        <>
            <div className="section planet_objects full_height-section">
                <div className="container">
                    <motion.div
                        className="h1"
                        variants={h1_animation}
                        initial="hidden"
                        animate="visible"
                    >
                        Планируемые объекты
                    </motion.div>
                    <main>
                        <div className="left_col">
                            <div className="top_section">
                                <div className={`h2 ${activeStyle}`}>
                                    <div>{currentObject?.title}</div>
                                </div>
                            </div>
                            <div className="bottom_section">
                                <motion.div
                                    className="arrow"
                                    onClick={() => setOpenModal("planet_object_modal", currentObject)}
                                    variants={h1_animation}
                                    initial="hidden"
                                    animate="visible"
                                    custom={0.5}
                                >
                                    <img src={arrow} alt="arrow"/>
                                </motion.div>
                                <div className={`text  ${activeStyle}`}>
                                    {currentObject?.short_description}
                                </div>
                            </div>
                        </div>
                        <motion.div
                            className="right_col"
                            variants={h1_animation}
                            initial="hidden"
                            animate="visible"
                            custom={1.5}
                        >
                            <Slider
                                refSlider={refSlider}
                                imagesData={imagesSlider}
                                changeSlide={changeSlide}
                                needCursor={true}
                                selectItem={() => setOpenModal("planet_object_modal", currentObject)}
                            />
                        </motion.div>
                    </main>
                </div>
            </div>
            <div className="planet_objects-mobile" ref={refPageMobile}>
                <div className="h1_title">
                    <span>Планируемые</span>
                    <span>объекты</span>
                </div>
                <div className="slider_section">
                    <MobileSlider
                        data={imagesSlider}
                        setOpenModal={setOpenModal}
                        refSlider={refSliderMobile}
                        setActiveSlide={changeSlide}
                        isPlanetObject={true}
                        selectItem={() => setOpenModal("planet_object_modal", currentObject)}
                        key={"isPlanetObject"}
                    />
                    <div className="title">
                        <div>
                            {currentObject?.title}
                        </div>
                    </div>
                    <div className="text">
                        {currentObject?.short_description}
                    </div>
                    <Dots
                        data={imagesSlider}
                        refSlider={refSliderMobile}
                        setActiveSlide={setActiveSlide}
                        activeSlide={activeSlide}
                    />
                </div>
            </div>
        </>
    );
};

export default PlanetObjects;