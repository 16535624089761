import React, { useEffect, useRef, useState } from 'react';
import requests from "../../../axios/requests";
import SliderComponent from "../../../components/Slider/SliderComponent";

import './PlanetObjectModal.scss';

const PlanetObjectModal = ({currentItemModal}) => {
    const [data, setData] = useState({});
    const refSlider = useRef();


    useEffect(() => {
        requests.get_item_id("project", currentItemModal?.id)
            .then(res => setData(res.data))
            .catch(err => console.error(err))
    }, [])
    return (
        <div>
            <div className="date">{data?.finished_at}</div>
            <div className="h1">{data?.title}</div>
            {data?.photos?.length > 0 &&
                <SliderComponent
                    refSlider={refSlider}
                    imagesData={data.photos}
                />
            }
            {data?.short_description &&
                <div className="text">
                    {data.short_description}
                </div>
            }
            {data?.description &&
                <div className="__html" dangerouslySetInnerHTML={{__html: data.description}}/>
            }
        </div>
    );
};

export default PlanetObjectModal;