import React from 'react';
import './Dots.scss';

const Dots = ({data, activeSlide, setActiveSlide, refSlider}) => {
    return (
        <>
            {data?.length > 0 &&
                <div className="dots">
                    {data.map((_, indx) =>
                        <div
                            className={activeSlide === indx ? "active" : ""}
                            onClick={() => {
                                refSlider?.current?.slickGoTo(indx)
                                setActiveSlide(indx)
                            }}
                            key={indx}
                        />
                    )}
                </div>
            }
        </>
    );
};

export default Dots;