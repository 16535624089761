import axios from "axios";

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        /*  "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",*/
    }
});

const responseErrorHandler = error => {
    return Promise.reject(error);
};
const responseSuccessHandler = response => {
    return response;
};

apiClient.interceptors.response.use(
    response => responseSuccessHandler(response),
    error => responseErrorHandler(error)
);

export default {
    create_application_service(request) {
        return apiClient.post("v1/application/service/", request)
    },
    create_application_vacancy(request) {
        return apiClient.post("v1/application/vacancy/", request)
    },
    fetch_services_machinery(type, page, page_size) {
        return apiClient.get(`v1/landing/service/`, {params: {type, page, page_size}})
    },
    fetch_services_reqular(type, page, page_size) {
        return apiClient.get(`v1/landing/service/`, {params: {type, page, page_size}})
    },
    fetch_photo_album(page, page_size) {
        return apiClient.get(`v1/landing/photo_album/`, {params: {page, page_size}})
    },
    fetch_object(type, page, page_size) {
        return apiClient.get(`v1/landing/project/`, {params: {type, page, page_size}})
    },
    fetch_news(type, page, page_size) {
        return apiClient.get(`v1/landing/news/`, {params: {type, page, page_size}})
    },
    fetch_vacancy(page, page_size) {
        return apiClient.get(`v1/landing/vacancy/`, {params: { page, page_size}})
    },
    get_item_id(type, id) {
        return apiClient.get(`v1/landing/${type}/${id}/`)
    },

}