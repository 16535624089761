import React, { useEffect, useRef, useState } from "react";
import requests from "./axios/requests";
import ReactFullpage from "@fullpage/react-fullpage";

import Services from "./sections/Services/Services";
import Orders from "./sections/Orders/Orders";
import PhotoGalery from "./sections/PhotoGalery/PhotoGalery";
import PlanetObjects from "./sections/PlanetObject/PlanetObjects";
import BuildObjects from "./sections/BuiltObjects/BuildObjects";
import News from "./sections/News/News";
import Vacancy from "./sections/Vacancy/Vacancy";
import Contacts from "./sections/Contacts/Contacts";
import Preview from "./sections/Preview/Preview";

import ModalContainer from "./components/ModalContainer/ModalContainer";
import { Preview as ModalPreview } from "./sections/Preview/PreviewModal/PreviewModal";
import ServicesModal from "./sections/Services/ServicesModal/ServicesModal";
import OrdersModal from "./sections/Orders/OrdersModal/OrdersModal";
import PhotoGaleryModal from "./sections/PhotoGalery/PhotoGaleryModal/PhotoGaleryModal";
import PlanetObjectModal from "./sections/PlanetObject/PlanetObjectModal/PlanetObjectModal";
import BuildObjectsModal from "./sections/BuiltObjects/BuildObjectsModal/BuildObjectsModal";
import NewsModal from "./sections/News/NewsModal/NewsModal";
import VacancyModal from "./sections/Vacancy/VanacyModal/VacancyModal";
import PhotoGaleryWorkDays from "./sections/PhotoGalery/PhotoGaleryWorkDays/PhotoGaleryWorkDays";
import Header from "./components/Header/Header";
import close from "./assets/close.svg";
import { Transition } from "react-transition-group";

function App() {
    const anchors = [
        "preview_", "services_", "orders_", "photo_galery_", "planned_objects_",
        "build_objects_", "news_", "vacancy_", "contacts_"
    ];
    const [openModal, setOpenModal] = useState(false);
    const [currentModal, setCurrentModal] = useState("preview_modal");
    const [currentItemModal, setCurrentItemModal] = useState("");
    const [fullPageApi, setFullPageApi] = useState(null);
    const [currentSectionName, setCurrentSectionName] = useState("preview");
    const [resizeHeight, setResizeHeight] = useState(null);
    const [resizeWidth, setResizeWidth] = useState(null);
    const [activeSection, setActiveSection] = useState(null);
    const [currentColorHeader, setCurrentColorHeader] = useState("");
    const [scrollToAnchor, setScrollToAnchor] = useState({
        "preview": false,
        "services": false,
        "orders": false,
        "photo_galery": false,
        "planned_objects": false,
        "build_objects": false,
        "news": false,
        "vacancy": false,
        "contacts": false
    });

    const tooltips = ["Главная", "Спецтехника", "Услуги", "Фотогалерея", "Планируемые объекты", "Построенные объекты", "Новости", "Вакансии", "Контакты"];
    const contentsModals = {
        "preview_modal": <ModalPreview/>,
        "services_modal": <ServicesModal currentItemModal={currentItemModal}/>,
        "orders_modal": <OrdersModal currentItemModal={currentItemModal}/>,
        "photo_galery_modal": <PhotoGaleryModal currentItemModal={currentItemModal}/>,
        "photo_work_days_modal": <PhotoGaleryWorkDays currentItemModal={currentItemModal}/>,
        "planet_object_modal": <PlanetObjectModal currentItemModal={currentItemModal}/>,
        "build_object_modal": <BuildObjectsModal currentItemModal={currentItemModal}/>,
        "news_modal": <NewsModal currentItemModal={currentItemModal}/>,
        "vacancy_modal": <VacancyModal currentItemModal={currentItemModal}/>
    };

    let closeModal = () => {
        setOpenModal(false);
    }
    let changeHeight = (event) => {
        setResizeHeight(event.srcElement.innerHeight)
        setResizeWidth(event.srcElement.innerWidth)
    }
    let scrollTo = (indx) => {
        switch (indx) {
            case 0:
                setScrollToAnchor(prev => ({...prev, ["preview"]: true}))
                setTimeout(() =>
                    setScrollToAnchor(prev => ({...prev, ["preview"]: false})), 1500)
                break;
            case 1:
                setScrollToAnchor(prev => ({...prev, ["services"]: true}))
                setTimeout(() =>
                    setScrollToAnchor(prev => ({...prev, ["services"]: false})), 1500)
                break;
            case 2:
                setScrollToAnchor(prev => ({...prev, ["orders"]: true}))
                setTimeout(() =>
                    setScrollToAnchor(prev => ({...prev, ["orders"]: false})), 1500)
                break;
            case 3:
                setScrollToAnchor(prev => ({...prev, ["photo_galery"]: true}))
                setTimeout(() =>
                    setScrollToAnchor(prev => ({...prev, ["photo_galery"]: false})), 1500)
                break;
            case 4:
                setScrollToAnchor(prev => ({...prev, ["planned_objects"]: true}))
                setTimeout(() =>
                    setScrollToAnchor(prev => ({...prev, ["planned_objects"]: false})), 1500)
                break;
            case 5:
                setScrollToAnchor(prev => ({...prev, ["build_objects"]: true}))
                setTimeout(() =>
                    setScrollToAnchor(prev => ({...prev, ["build_objects"]: false})), 1500)
                break;
            case 6:
                setScrollToAnchor(prev => ({...prev, ["news"]: true}))
                setTimeout(() =>
                    setScrollToAnchor(prev => ({...prev, ["news"]: false})), 1500)
                break;
            case 7:
                setScrollToAnchor(prev => ({...prev, ["vacancy"]: true}))
                setTimeout(() =>
                    setScrollToAnchor(prev => ({...prev, ["vacancy"]: false})), 1500)
                break;
            case 8:
                setScrollToAnchor(prev => ({...prev, ["contacts"]: true}))
                setTimeout(() =>
                    setScrollToAnchor(prev => ({...prev, ["contacts"]: false})), 1500)
                break;
        }
    }
    useEffect(() => {
        /*  if (openModal) // запретить прокрутку если открыто модальное окно
              document.body.style.overflow = "hidden !important";
          else
              document.body.style.overflow = "hidden !important";*/
    }, [openModal])
    useEffect(() => {
        setResizeHeight(window.innerHeight);
        setResizeWidth(window.innerWidth);

        window.addEventListener("resize", changeHeight);
        return () =>
            window.removeEventListener("resize", changeHeight);
    }, [])
    return (
        <div className="app">
            <Header
                currentSectionName={currentSectionName}
                currentColorHeader={currentColorHeader}
                scrollToSection={(indx) => fullPageApi.moveTo(indx)}
                scrollTo={scrollTo}
            />
            {(resizeHeight > 640 && resizeWidth > 990)
                ? <ReactFullpage
                    navigationTooltips={tooltips}
                    navigation={anchors}
                    anchors={anchors}
                    normalScrollElements={".modal_parent, .menu_drop"}
                    onLeave={(origin, destination, direction) => {
                    }}
                    afterLoad={(event, event_2) => {
                        setActiveSection(event_2?.index)
                    }}
                    render={({state, fullpageApi}) => {
                        setFullPageApi(fullpageApi)
                        /*      if (openModal)
                                  fullpageApi?.setAllowScrolling(false)*/
                        setCurrentSectionName(state?.destination?.anchor)
                        return (
                            <div>
                                <Preview
                                    activeSection={activeSection}
                                    setOpenModal={(current) => {
                                        setOpenModal(true);
                                        setCurrentModal(current)
                                    }}
                                />
                                <Services
                                    activeSection={activeSection}
                                    setOpenModal={(currentModal, currentItem) => {
                                        setOpenModal(true);
                                        setCurrentModal(currentModal)
                                        setCurrentItemModal(currentItem)
                                    }}
                                />
                                <Orders
                                    activeSection={activeSection}
                                    setOpenModal={(currentModal, currentItem) => {
                                        setOpenModal(true);
                                        setCurrentModal(currentModal)
                                        setCurrentItemModal(currentItem)
                                    }}
                                />
                                <PhotoGalery
                                    setOpenModal={(currentModal, currentItem) => {
                                        setOpenModal(true);
                                        setCurrentModal(currentModal)
                                        setCurrentItemModal(currentItem)
                                    }}
                                />
                                <PlanetObjects
                                    activeSection={activeSection}
                                    setOpenModal={(currentModal, currentItem) => {
                                        setOpenModal(true);
                                        setCurrentModal(currentModal)
                                        setCurrentItemModal(currentItem);
                                    }}
                                />
                                <BuildObjects
                                    activeSection={activeSection}
                                    setOpenModal={(currentModal, currentItem) => {
                                        setOpenModal(true);
                                        setCurrentModal(currentModal)
                                        setCurrentItemModal(currentItem);
                                    }}
                                />
                                <News
                                    activeSection={activeSection}
                                    setOpenModal={(currentModal, currentItem) => {
                                        setOpenModal(true);
                                        setCurrentModal(currentModal)
                                        setCurrentItemModal(currentItem);
                                    }}
                                />
                                <Vacancy
                                    activeSection={activeSection}
                                    setOpenModal={(currentModal, currentItem) => {
                                        setOpenModal(true);
                                        setCurrentModal(currentModal)
                                        setCurrentItemModal(currentItem);
                                    }}
                                />
                                <Contacts
                                    activeSection={activeSection}
                                />
                            </div>
                        );
                    }}
                />
                :
                <>
                    <Preview
                        scrollToAnchor={scrollToAnchor.preview}
                        setCurrentColorHeader={setCurrentColorHeader}
                        setOpenModal={(current) => {
                            setOpenModal(true);
                            setCurrentModal(current)
                        }}
                    />
                    <Services
                        scrollToAnchor={scrollToAnchor.services}
                        setCurrentColorHeader={setCurrentColorHeader}
                        setOpenModal={(currentModal, currentItem) => {
                            setOpenModal(true);
                            setCurrentModal(currentModal)
                            setCurrentItemModal(currentItem)
                        }}
                    />
                    <Orders
                        scrollToAnchor={scrollToAnchor.orders}
                        setCurrentColorHeader={setCurrentColorHeader}
                        setOpenModal={(currentModal, currentItem) => {
                            setOpenModal(true);
                            setCurrentModal(currentModal)
                            setCurrentItemModal(currentItem)
                        }}
                    />
                    <PhotoGalery
                        scrollToAnchor={scrollToAnchor.photo_galery}
                        setCurrentColorHeader={setCurrentColorHeader}
                        setOpenModal={(currentModal, currentItem) => {
                            setOpenModal(true);
                            setCurrentModal(currentModal)
                            setCurrentItemModal(currentItem)
                        }}
                    />
                    <PlanetObjects
                        scrollToAnchor={scrollToAnchor.planned_objects}
                        setCurrentColorHeader={setCurrentColorHeader}
                        setOpenModal={(currentModal, currentItem) => {
                            setOpenModal(true);
                            setCurrentModal(currentModal)
                            setCurrentItemModal(currentItem);
                        }}
                    />
                    <BuildObjects
                        scrollToAnchor={scrollToAnchor.build_objects}
                        setCurrentColorHeader={setCurrentColorHeader}
                        setOpenModal={(currentModal, currentItem) => {
                            setOpenModal(true);
                            setCurrentModal(currentModal)
                            setCurrentItemModal(currentItem);
                        }}
                    />
                    <News
                        scrollToAnchor={scrollToAnchor.news}
                        setCurrentColorHeader={setCurrentColorHeader}
                        setOpenModal={(currentModal, currentItem) => {
                            setOpenModal(true);
                            setCurrentModal(currentModal)
                            setCurrentItemModal(currentItem);
                        }}
                    />
                    <Vacancy
                        scrollToAnchor={scrollToAnchor.vacancy}
                        setCurrentColorHeader={setCurrentColorHeader}
                        setOpenModal={(currentModal, currentItem) => {
                            setOpenModal(true);
                            setCurrentModal(currentModal)
                            setCurrentItemModal(currentItem);
                        }}
                    />
                    <Contacts
                        scrollToAnchor={scrollToAnchor.contacts}
                        setCurrentColorHeader={setCurrentColorHeader}
                    />
                </>
            }
            <ModalContainer
                openModal={openModal}
                closeModal={closeModal}
                currentModal={currentModal}
            >
                {contentsModals[currentModal]}
            </ModalContainer>
        </div>
    );
}

export default App;
