import React, { useEffect, useRef, useState } from 'react';
import requests from "../../axios/requests";

import icon_vacancy from '../../assets/vacancy/icon_vacancy.png';
import house from '../../assets/vacancy/house.png';
import arrow from '../../assets/vacancy/arrow.svg';
import './Vacancy.scss';
import { motion } from "framer-motion";
import helpFunctions from "../../helpers/helpFunctions";

const ItemList = ({label, speciality, click, indx, listAnimation}) => {
    return (
        <motion.li
            variants={listAnimation}
            initial="hidden"
            animate="visible"
            exit="hidden"
            custom={(indx + 1) * 0.3}
            onClick={() => click()}
        >
            <div className="label">
                {label}
            </div>
            {speciality &&
                <div className="info">
                    {speciality}
                </div>
            }
            <div className="more_row">
                <span>Подробнее</span>
                <div className="arrow">
                    <img src={arrow} alt="arrow"/>
                </div>
            </div>
        </motion.li>
    )
}
const Vacancy = ({setOpenModal, activeSection, setCurrentColorHeader, scrollToAnchor}) => {
    const [data, setData] = useState({});
    const [showListAnimation, setShowListAnimation] = useState(false);

    const refPageMobile = useRef();
    const h1_animation = {
        hidden: {opacity: 0},
        visible: (custom) => {
            if (showListAnimation)
                return {
                    opacity: 1,
                    transition: {
                        duration: 2,
                        delay: custom || 1
                    }
                }
        }
    };
    const listAnimation = {
        hidden: {opacity: 0},
        visible: (custom) => {
            if (showListAnimation)
                return {
                    opacity: 1,
                    transition: {
                        duration: 1.5,
                        delay: custom
                    }
                }
        }
    }

    let eventScroll = () => {
        helpFunctions.isScrolledIntoHeader(
            refPageMobile.current,
            "vacancy_color",
            setCurrentColorHeader
        )
    }

    useEffect(() => {
        requests.fetch_vacancy(1, 20)
            .then(res => setData(res.data))
            .catch(err => console.error(err))

        if (window.innerWidth < 991) {
            window.addEventListener("scroll", eventScroll);
            return () =>
                window.removeEventListener("scroll", eventScroll);
        }
    }, [])
    useEffect(() => {
        if (activeSection === 7)
            setShowListAnimation(true);
    }, [activeSection])
    useEffect(() => {
        if (scrollToAnchor)
            refPageMobile.current.scrollIntoView({block: "start", behavior: "smooth"})
    }, [scrollToAnchor])
    return (
        <>
            <div className="section vacancy full_height-section">
                <div className="container">
                    <div className="left_col">
                        <motion.div
                            className="h1"
                            variants={h1_animation}
                            initial="hidden"
                            animate="visible"
                        >
                            вакансии
                        </motion.div>
                        <div className="figure">
                            <img src={icon_vacancy} alt="icon_vacancy"/>
                        </div>
                        <div className="house_icon"/>
                    </div>
                    <main>
                        <div className="right_col">
                            {data?.results?.length > 0 &&
                                <ul className="list list_style">
                                    {data.results.map((elem, indx) =>
                                        <ItemList
                                            key={indx}
                                            indx={indx}
                                            listAnimation={listAnimation}
                                            label={elem.name}
                                            speciality={elem?.speciality}
                                            click={() => setOpenModal("vacancy_modal", elem)}
                                        />
                                    )}
                                </ul>
                            }
                        </div>
                    </main>
                </div>
            </div>
            <div className="vacancy_mobile" ref={refPageMobile}>
                <div className="top_section">
                    <div className="h1">
                        вакансии
                    </div>
                    <div className="preview_image">
                        <img src={icon_vacancy} alt="icon_vacancy"/>
                        <img src={house} alt=""/>
                    </div>
                </div>
                {data?.results?.length > 0 &&
                    <ul className="list">
                        {data.results.map((elem, indx) =>
                            <ItemList
                                key={indx}
                                label={elem.name}
                                speciality={elem?.speciality}
                                click={() => setOpenModal("vacancy_modal", elem)}
                            />
                        )}
                    </ul>
                }
            </div>
        </>
    )
};

export default Vacancy;