import React, { useState } from 'react';
import { Transition } from "react-transition-group";
import requests from "../../../axios/requests";

import arrow from '../../../assets/photoGalery/arrow.svg';
import './PhotoGaleryModal.scss';

const PhotoGaleryModal = ({currentItemModal}) => {
        const [showList, setShowList] = useState(true);
        const [currentGalery, setCurrentGalery] = useState({});

        let getCurrentAlbum = (id) => {
            requests.get_item_id("photo_album", id)
                .then(res => setCurrentGalery(res.data))
                .catch(err => console.error(err))
        }
        return (
            <div>
                {currentGalery?.date &&
                    <div className="date">{currentGalery?.date}</div>
                }
                <Transition
                    in={showList}
                    timeout={600}
                    unmountOnExit
                    mountOnEnter
                >
                    {state =>
                        <ul className={`galery_list ${state} list_style`}>
                            {currentItemModal && currentItemModal.length > 0 &&
                                currentItemModal.map((elem, indx) =>
                                    <li
                                        onClick={() => {
                                            setTimeout(() => {
                                                getCurrentAlbum(elem.id)
                                            }, 600)
                                            setShowList(false);
                                        }}
                                        key={indx}
                                    >
                                        <div className="year">{elem.year}</div>
                                        <div className="name">{elem.name}</div>
                                        <div>
                                            <span>Перейти</span>
                                            <div className="arrow">
                                                <img src={arrow} alt="arrow"/>
                                            </div>
                                        </div>
                                    </li>
                                )}
                        </ul>
                    }
                </Transition>
                <Transition
                    in={Object.values(currentGalery).length > 0}
                    timeout={600}
                    unmountOnExit
                    mountOnEnter
                >
                    {state =>
                        <div className={`current_galery ${state}`}>
                                <div className="year">{currentGalery?.year}</div>
                            <div className="name">{currentGalery?.name}</div>
                            {currentGalery.photos.map((elem, indx) =>
                                <img
                                    src={elem.file}
                                    alt="current_galery"
                                    key={indx}
                                />
                            )}
                        </div>
                    }
                </Transition>
            </div>
        );
    }
;

export default PhotoGaleryModal;